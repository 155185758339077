import * as React from "react";
import { NavLink } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";



function ResponsiveAppBar() {
  return (
    <AppBar position="static" className="header">
      <CssBaseline />
      <Box>
        <Toolbar disableGutters className="inner-wrapper">
          <Typography
            key="logo-wrapper"
            component={NavLink}
            to="/"
            className="logo-wrapper"
          >
            <img
              alt="Lee Enterprises Logo"
              key="logo"
              src="images/LogoColor.svg"
            />
          </Typography>
          <Box key="label" sx={{ flexGrow: 1 }} />
        </Toolbar>
      </Box>
    </AppBar>
  );
}
export default ResponsiveAppBar;
