import { Box } from "@mui/material"
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";

const MarkdownRenderer = ({ children, isDarkMode }) => {
  const tableStyles = {
    borderCollapse: "collapse",
    width: "100%",
  };
  const thStyles = {
    border: "1px solid black",
    padding: "5px",
    backgroundColor: isDarkMode === true ? "grey" : "#8080808c",
  };

  const tdStyles = {
    border: "1px solid black",
    padding: "5px",
  };

  const imgStyles = {
    maxWidth: "100%",
  };
  const aStyles = {
    // color: theme.palette.mode === 'dark' ? 'white' : 'blue',
    textDecoration: "underline",
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              style={darcula}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
        table({ children }) {
          return <table style={tableStyles}>{children}</table>;
        },
        th({ children }) {
          return <th style={thStyles}>{children}</th>;
        },
        td({ children }) {
          return <td style={tdStyles}>{children}</td>;
        },
        img({ src, alt }) {
          return <img style={imgStyles} src={src} alt={alt} />;
        },
        a({ href, children }) {
          return (
            <a href={href} style={aStyles}>
              {children}
            </a>
          );
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
};


const Result = ({ message }) => {

  return (
    <>
      <Box className="result-box">
        <MarkdownRenderer>
          {message.answer}
        </MarkdownRenderer>
      </Box>
    </>
  )

}
export default Result;