import { Grid } from "@mui/material";
import { AINews, FeedsAndPodcasts, Footer, Header, Slider, NewsFeed, Highlights, SearchArea } from "../../component";

const HomePage = () => {
  return (
    <>
      <Header />
      <div style={{ background: "#161515", padding: "0 0 50px" }}>
        <Slider />
        <SearchArea />
        <NewsFeed />
        <AINews />
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Highlights />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <FeedsAndPodcasts />
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
