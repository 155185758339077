import React, { useState } from "react";
import { Box, Grid, Typography, Pagination } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ImageList } from "../ImageList";
import "../style.css";

const mock_data = [
  {
    image: "images/candidate_photos/jon_tester.jpeg",
    name: "Jon Tester",
    post: "US Senate (Democrat)",
    about:
      "Jon Tester is running for re-election to represent Montana in the US Senate.",
    websiteLink: "https://jontester.com/",
  },
  {
    image: "images/candidate_photos/tim_sheehy.jpeg",
    name: "Tim Sheehy",
    post: "US Senate (Republican)",
    about:
      "Tim Sheehy is a Republican candidate running for the US Senate in Montana.",
    websiteLink: "https://timformt.com/",
  },
  {
    image: "",
    name: "Michael Downey",
    post: "US Senate (Libertarian)",
    about:
      "Michael Downey is a Libertarian candidate running for the US Senate in Montana.",
    websiteLink: "",
  },
  {
    image: "",
    name: "Sid Daoud",
    post: "US Senate (Libertarian)",
    about:
      "Sid Daoud is a Libertarian candidate running for the US Senate in Montana.",
    websiteLink: "",
  },
  {
    image: "",
    name: "John B. Driscoll",
    post: "Montana's 2nd Congressional District (Democrat)",
    about:
      "John B. Driscoll is the Democratic candidate for Montana's 2nd Congressional District.",
    websiteLink: "",
  },
  {
    image: "images/candidate_photos/troy_downing.jpeg",
    name: "Troy Downing",
    post: "Montana's 2nd Congressional District (Republican)",
    about:
      "Troy Downing is the Republican candidate for Montana's 2nd Congressional District.",
    websiteLink: "https://www.troydowning.com/",
  },
  {
    image: "images/candidate_photos/greg_gianforte.jpeg",
    name: "Greg Gianforte",
    post: "Governor (Republican)",
    about:
      "Greg Gianforte is the current Governor of Montana, running for re-election.",
    websiteLink: "https://gregformontana.com/",
  },
  {
    image: "images/candidate_photos/ryan_busse.jpg",
    name: "Ryan Busse",
    post: "Governor (Democrat)",
    about:
      "Ryan Busse is the Democratic candidate running for Governor of Montana.",
    websiteLink: "https://busseformontana.com/",
  },
  {
    image: "images/candidate_photos/kaiser_leib.png",
    name: "Kaiser Leib",
    post: "Governor (Libertarian)",
    about:
      "Kaiser Leib is a Libertarian candidate running for Governor of Montana.",
    websiteLink: "https://kaiserformontana.us/",
  },
  {
    image: "",
    name: "Jerry Lynch",
    post: "Montana Supreme Court Chief Justice",
    about:
      "Jerry Lynch is running for Chief Justice of the Montana Supreme Court.",
    websiteLink: "",
  },
  {
    image: "",
    name: "Cory Swanson",
    post: "Montana Supreme Court Chief Justice",
    about:
      "Cory Swanson is running for Chief Justice of the Montana Supreme Court.",
    websiteLink: "",
  },
  {
    image: "",
    name: "Katherine M. Bidegaray",
    post: "Montana Supreme Court Associate Justice",
    about:
      "Katherine M. Bidegaray is running for Associate Justice of the Montana Supreme Court.",
    websiteLink: "",
  },
  {
    image: "",
    name: "Dan Wilson",
    post: "Montana Supreme Court Associate Justice",
    about:
      "Dan Wilson is running for Associate Justice of the Montana Supreme Court.",
    websiteLink: "",
  },
  {
    image: "images/candidate_photos/joel_krautter.jpg",
    name: "Joel Krautter",
    post: "House of Representatives (Republican)",
    about:
      "Joel Krautter is a Republican candidate running for the House of Representatives.",
    websiteLink: "https://joelformontana.com/",
  },
  {
    image: "",
    name: "Kyle Austin",
    post: "House of Representatives (Republican)",
    about:
      "Kyle Austin is a Republican candidate running for the House of Representatives.",
    websiteLink: "",
  },
  {
    image: "images/candidate_photos/kenneth_bogner.jpg",
    name: "Kenneth Bogner",
    post: "House of Representatives (Republican)",
    about:
      "Kenneth Bogner is a Republican candidate running for the House of Representatives.",
    websiteLink: "https://kennethbogner.com/",
  },
];

const AINews = () => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const paginatedData = mock_data.slice(startIndex, startIndex + itemsPerPage);
  return (
    <>
      <Box className="newAi" minHeight={1000} height={"auto"}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "left",
                fontStyle: " italic",
                fontWeight: "700",
                pb: 4,
              }}
            >
              CANDIDATES
            </Typography>
            <Box className="news-list-wrapper">
              {paginatedData.map((article, index) => {
                const { name, post, about, image, websiteLink } = article;
                console.log(image);
                return (
                  <Box className="news-list">
                    <Grid container spacing={2} sx={{ pb: 3 }} key={index}>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <img
                          key={name}
                          alt={name}
                          src={image || "images/dummy-image.png"}
                          className="articleImage"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={7}
                        lg={7}
                        xl={7}
                        className="content-wrapper"
                      >
                        <Typography
                          variant="subtitle1"
                          target="_blank"
                          className="title"
                          sx={{ color: "#FFFFFF" }}
                        >
                          {name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ pt: 2, fontWeight: "bold" }}
                        >
                          {post}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="#FFFFFF"
                          sx={{ pt: 2 }}
                        >
                          {about}
                        </Typography>
                        {websiteLink && (
                          <Typography
                            as={NavLink}
                            to={websiteLink}
                            target="__blank"
                            variant="body2"
                            color="#FFFFFF"
                            sx={{ pt: 2 }}
                          >
                            Visit the website
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </Box>
            <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                count={Math.ceil(mock_data.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            xl={7}
            className="photos-box-wrapper"
          >
            <Typography
              variant="h6"
              sx={{
                textAlign: "left",
                fontStyle: " italic",
                fontWeight: "700",
                pb: 4,
              }}
            >
              PHOTOS
            </Typography>
            <ImageList />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default AINews;
