import React, {  useState } from "react";
import { Box, Pagination, Typography } from "@mui/material"
import { useGetData } from "../../hooks";

const Videos =  React.memo(() => {
    const [page, setPage] = useState(1);
    const itemsPerPage = 4;
    const { loading, data, error } = useGetData("/election/videos/");

    if (loading) {
        return "Loading...";
    }

    if (error) {
        return JSON.stringify(error);
    }

    const {
        rss: {
            channel: {
                item },
        },
    } = data;

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const startIndex = (page - 1) * itemsPerPage;
    const paginatedData = item.slice(startIndex, startIndex + itemsPerPage);

    const getVideoId = (url) => {
        const arr = url.split('?v=');
        const id = arr[arr.length-1];
        const finalUrl = "https://www.youtube.com/embed/" + id;
       return finalUrl;
    }

    return (
        <>
            <Box className="video-wrapper">
                <Box>
                <Typography variant="h5" className="sub-title">
                    VIDEOS
                </Typography>
                    <Box>
                        <img alt="main-photo" src="images/generalImages/MainBIGImagegeneralelection.png" style={{ width: "100%", height: "30%" }} />
                    </Box>
                    <ul className="video-list-wrapper">
                        {paginatedData.map((item) => {
                            const cols = item.featured ? 2 : 1;
                            const rows = item.featured ? 2 : 1;
                            const { title, link } = item;
                            const updatedLink=getVideoId(link);

                            return (
                                <li
                                    key={title}
                                    cols={cols}
                                    rows={rows}
                                    className="imageList"
                                >
                                    <iframe src={updatedLink} allow="fullscreen" title="test"></iframe>
                                </li>
                            );
                        })}
                    </ul>

            </Box>
            <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                    className="paginations"
                    count={Math.ceil(item.length / itemsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                />
            </Box>
            </Box>
        </>
    )

});
export default Videos;