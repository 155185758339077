import React, { useEffect, useState } from "react";
import Button from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Switch from "@mui/material/Switch";
import "./login.css";

export default function SignInSide() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

useEffect(() => {
  if (currentUser) {
    navigate("/");
  }
    // eslint-disable-next-line
},[currentUser]);

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      navigate("/upload");
      // if (userCredential.user.emailVerified) {
      // } else {
      //   setError("Please verify your email before logging in.");
      //   await signOut(auth);
      // }
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <Grid
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundColor: '#141414',
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        margin: "0",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "space-between",
      }}
      container
      component="main"
    >
      <CssBaseline />
     
    <Box sx={{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      padding:'10px 20px',
    }}>
    <Typography
           sx={{
             display: 'block',           
           }}
         >
           <img alt="logo" src={'./images/LogoWhite.svg'} style={{ width: "200px", height: "45px" }} />
         </Typography>
       
    </Box>
  
    <Grid
        className="form-container"
        sx={{
          margin: "auto",
          borderRadius: "10px",
          background:  '#141414',
          border: '1px solid #20808d',
          boxShadow: '0px 0px 8px 5px rgba(32,128,141,1)',
        }}
        item
        xs={12}
        sm={6}
        md={3}
        component={Paper}
        elevation={6}
        square
      >
        <Box
          sx={{
            background:'#20808d',
            color: "rgb(52, 71, 103)",
            borderRadius: "0.5rem",
            boxShadow:
              "rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem",
          }}
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
        {/* <Typography
            sx={{
             display: 'block',
             fontSize:'20px',
             fontWeight:'500',
             color:'white',
           }}
         >
           HyperLocal Chat Agent
         </Typography> */}
          <Typography variant="h4" fontWeight="medium" color="white" mt={1}
           sx={{
            display: 'block',
            fontSize:'16px',
            fontWeight:'500',
            color:'white',
          }}
          >
            Sign in
          </Typography>
          {/* <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={2}>
              <Typography
                component={Link}
                href="#"
                variant="body1"
                color="white"
              >
                <FacebookIcon color="inherit" />
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                component={Link}
                href="#"
                variant="body1"
                color="white"
              >
                <GitHubIcon color="inherit" />
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                component={Link}
                href="#"
                variant="body1"
                color="white"
              >
                <GoogleIcon color="inherit" />
              </Typography>
            </Grid>
          </Grid> */}
        </Box>
        <Box
        className= {`inputForm ${'dark-mode-form'}`}
          sx={{
            py: "10px",
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleLogin}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              value={email}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined" 
              sx={{
                borderColor: 'black',
                backgroundColor:"transparent"
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              variant="outlined" 
            />
            <Box display="flex" alignItems="center" ml={-1}>
              <Switch
                value={"Remember"}
                checked={rememberMe}
                onChange={handleSetRememberMe}
                className= {'dark-switch'}
                sx={{
                     color: 'white'
                }}
              />
              <Typography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{
                  cursor: "pointer",
                  userSelect: "none",
                  ml: -1,
                  textTransform: "capitalize",
                  color: 'white'
                }}
              >
                &nbsp;&nbsp;Remember me
              </Typography>
            </Box>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <Button
              type="submit"
              fullWidth
              loading={loading}
              variant="contained"
              sx={{ mt: 3, mb: 2,
                background:'#20808d',  
                border:'1px solid #20808d', 
                '&:hover': {
                color: '#20808d', 
                background:'#141414',
                transition: 'all 0.3s ease',
              },  }}
            >
              Sign In
            </Button>
            {/* <Grid container>
              <Grid
                sx={{
                  margin: "auto",
                  marginTop: "24px",
                  marginBottom: "8px",
                  textAlign: "center",
                  color: 'white'
                }}
              >
                Don't have an account?{" "}
                <Link
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: '#20808d'
                  }}
                  to="/signup"
                >
                  Sign Up
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
