import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box } from "@mui/material";


function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${height * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function CustomImageList() {
  return (
    <Box className="imagelistbox">
      <Box style={{position:'relative'}}>
        <img alt="main-photo" src="images/generalImages/MainBIGImagegeneralelection.png" style={{ width: "100%" }} className="main-photo"/>
      </Box>   
        <ImageList sx={{transform: "translateZ(0)"}} >
        {itemData.map((item) => {
          const cols = item.featured ? 2 : 1;
          const rows = item.featured ? 2 : 1;
          
          return (
            <ImageListItem
              key={item.img}
              cols={cols}
              rows={rows}
              className="imageList"
              sx={{ padding: "5px" }}
             >
              <img
                {...srcset(item.src, 250, 200, rows, cols)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </Box>
  );
}
const itemData = [
  { id: 2, src: "images/generalImages/shutterstock_2440014509.jpg" },
  { id: 3, src: "images/generalImages/shutterstock_2456512049.jpg" },
  { id: 4, src: "images/generalImages/shutterstock_2484375141.jpg" }
];
