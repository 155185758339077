import { Box, Pagination, Typography } from "@mui/material";
import { useGetData } from "../../hooks";
import { NavLink } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { get } from "../../services/axiosService";

const RelatedContent = ({ input, followUpQues }) => {
  const [imagess, setNewsImages] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 2;
  const [relatedContents, setRelatedContents] = useState([]);
  const { data, error } = useGetData(`/related-content/${input}`);

  const fetchOgImage = async (link, index) => {
    try {
      const response = await get("/link-preview/", { url: link });
      if (response?.statusText === "OK") {
        const {
          data: { image },
        } = response;

        if (image) {
          setNewsImages((prevState) => {
            const newState = [...prevState];
            newState[index] = image;
            return newState;
          });
        } else {
          setNewsImages((prevState) => {
            const newState = [...prevState];
            newState[index] = "./images/dummy-image.png";
            return newState;
          });
        }
      } else {
        setNewsImages((prevState) => {
          const newState = [...prevState];
          newState[index] = "./images/dummy-image.png";
          return newState;
        });
      }
    } catch (error) {
      setNewsImages((prevState) => {
        const newState = [...prevState];
        newState[index] = "./images/dummy-image.png";
        return newState;
      });
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const paginatedData = relatedContents.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  useEffect(() => {
    if (data?.length) {
      localStorage.setItem("related-contents", JSON.stringify(data));
      setRelatedContents(data);
      data.forEach((item, index) => {
        const { url } = item;
        fetchOgImage(url, index);
      });
    } else {
      // const storedContent = localStorage.getItem("related-contents");
      // setRelatedContents(JSON.parse(storedContent));
    }
  }, [data]);

  // useEffect(() => {
  //     if (followUpQues) {
  //         const storedContent = localStorage.getItem("related-contents");
  //         setRelatedContents(JSON.parse(storedContent));
  //     }
  // }, [followUpQues])

  if (error) {
    return JSON.stringify(error);
  }

  return (
    <>
      <Box className="related-wrapper">
        {paginatedData.length && (
          <Typography variant="h5" className="sub-title">
            RELATED CONTENT
          </Typography>
        )}
        <Box className="related-content">
          {paginatedData?.map((item, index) => {
            const { title, url } = item;
            const { createdAt } = item || "20-07-24";
            return (
              <Box key={index} className="related-content-list">
                <Box className="content-wrapper">
                  <Typography variant="body2" color="#FFFFFF">
                    {createdAt}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    as={NavLink}
                    to={url}
                    target="_blank"
                    color="#FFFFFF"
                  >
                    {title}
                  </Typography>
                </Box>
                <img alt={title} src={imagess[index]} />
              </Box>
            );
          })}
        </Box>
        <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            className="paginations"
            count={Math.ceil(relatedContents.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </Box>
    </>
  );
};
export default memo(RelatedContent, () => true);
