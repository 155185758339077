import { Box, IconButton, TextField } from "@mui/material"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const FollowUpQuestion = ({ followUpQues,setFollowUpQues,handleSend }) => {

    const handleClick = (followUp) => {
        handleSend(followUp);
    }

    return (
        <>
            <Box className="follow-up-wrapper">
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Ask a followup question"
                    value={followUpQues}
                    onChange={(e) => setFollowUpQues(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSend(followUpQues);
                        }
                    }}
                />
                <IconButton
                    aria-label="search"
                    sx={{
                        background: "f1950e",
                        color: "white",
                        borderRadius: "20px",
                        "&:hover": {
                            backgroundColor: "#303f9f",
                        },
                    }}
                    onClick={() => handleClick(followUpQues)}
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </Box>
        </>
    )
}

export default FollowUpQuestion;