const envVars = process.env;

export const configuration = {
  baseUrl: envVars.REACT_APP_SERVICE_BASE_URL || "https://ai-paris-ctfvbegceedwfkbb.eastus-01.azurewebsites.net/api/",
  googleAnalyticsKey: envVars.REACT_APP_GA_KEY || "G-Q84V6RBT1Z",
  firebaseApiKey: envVars.REACT_APP_FIREBASE_API_KEY,
  firebaseAuthDomain: envVars.REACT_APP_FIREBASE_AUTH_DOMAIN,
  firebaseProjectId: envVars.REACT_APP_FIREBASE_PROJECT_ID,
  firebaseStorageBucket: envVars.REACT_APP_FIREBASE_STORAGE_BUCKET,
  firebaseMessagingSenderId: envVars.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  firebaseAppId: envVars.REACT_APP_FIREBASE_APP_ID,
};
