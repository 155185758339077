const FAQ = [
  "When is the Montana 2024 Election Day?",
  "What are the official dates for early voting in the Montana 2024 Elections?",
  "How can I register to vote in the Montana 2024 Elections?",
  "Who are the candidates running in the Montana 2024 Governor race?",
  "What are the key issues in the Montana 2024 Senate race?",
  "Where can I find my polling location for the Montana 2024 Elections?",
  "How can I volunteer for a campaign in the Montana 2024 Elections?",
  "What are the mail-in voting deadlines for the Montana 2024 Elections?",
  "How can I watch live election coverage for the Montana 2024 Elections?",
  "What is the process for absentee voting in Montana 2024?",
  "Are there any special accommodations for voters with disabilities in the Montana 2024 Elections?",
  "How can I get to my polling place using public transportation in Montana?",
  "What are the best resources to learn about the candidates in Montana's 2024 Elections?",
  "What security measures are in place for the Montana 2024 Elections?",
  "Can I bring my completed ballot to the polling station on Election Day?",
  "How can I find information about specific candidates running in the Montana 2024 Elections?",
  "What are the rules for campaigning near polling locations in Montana?",
  "Where can I find official Montana 2024 Election merchandise?",
  "What are the environmental policies of the candidates in the Montana 2024 Elections?",
  "How can I stay updated with the latest news and results from the Montana 2024 Elections?",
];

export const getRandomPrompts = () => {
    let shuffled = FAQ.sort(() => 0.5 - Math.random());
    // Get sub-array of first n elements after shuffled
    return shuffled.slice(0, 4);
}
