import { Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import { HomePage, Results, Login } from "./pages";
import PrivateRoute from "./PrivateRoute";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
      </Routes>
      <PrivateRoute>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/result" element={<Results />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </PrivateRoute>
    </AuthProvider>
  );
}
// function App() {
//   useEffect(() => {
//     ReactGA.initialize(configuration.googleAnalyticsKey);
//     ReactGA.send({ hitType: "pageview", page: "/", title: "Home page" });
//   }, []);
//   return (
//     <Routes>
//       <Route path="/" element={<HomePage />} />
//       <Route path="/result" element={<Results />} />
//       <Route path="*" element={<Navigate to="/" />} />
//     </Routes>
//   );
// }

export default App;
