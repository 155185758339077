import axios from "axios";
import { configuration } from "../config";

const axiosInstance = axios.create({
  baseURL: configuration.baseUrl,
});
const headers = {
  'Content-Type': 'application/json'
}
export const get = (path, params) =>
  axiosInstance.get(path, { params, headers });
export const post = (path, data, options) =>
  axiosInstance.post(path, data, { ...options });
export const put = (path, data) =>
  axiosInstance.put(path, data, { headers });
export const remove = (path) =>
  axiosInstance.delete(path, { headers });
