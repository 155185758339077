import { Box, Button, Grid, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useGetData } from "../../hooks";
import { handleGAEvents } from "../../utils/handleGAEvents";
import { gaConstants } from "../../constants/gaConstants";
import "../style.css";
import { formatDate } from "../../utils/formatDate";
import { NavLink } from "react-router-dom";

const Highlights = () => {
  const { loading, data, error } = useGetData("/election/highlights/");

  const itemsPerPage = 8; // Adjust number of items per page as needed
  const [page, setPage] = useState(1);
  if (loading) {
    return "Loading...";
  }

  if (error) {
    return JSON.stringify(error);
  }

  const { items } = data;
  const handleChangePage = (event, value) => {
    handleGAEvents(gaConstants.footerPagination);
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const paginatedData = items.slice(startIndex, startIndex + itemsPerPage);

  return (
    <Box className="highlights">
      <Typography
        variant="h5"
        sx={{
          textAlign: "left",
          fontStyle: " italic",
          fontWeight: "700",
          pb: 4,
        }}
      >
        HIGHLIGHTS
      </Typography>

      <Button variant="contained" sx={{ mb: 3 }}>
        ELECTION
      </Button>
      <br />
      <Grid container spacing={2}>
        {paginatedData.map((item, index) => {
          const { title, image, url, date_published, content_text } =
            item;
          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              mb={5}
              key={index}
              sx={{ justifyContent: "space-between" }}
            >
              <Box className="highlight-card">
                <img src={image} alt={title} className="highlight-feed-image" />
                <Box pl={2} className="highlight-content">
                  <Typography
                    variant="body2"
                    className="createdAt"
                    color="#FFFFFF"
                    sx={{ pb: 1 }}
                  >
                    {formatDate(date_published)}
                  </Typography>
                  <div onClick={() => handleGAEvents(gaConstants.highlights)}>
                    <Typography
                      style={{ textDecoration: "none" }}
                      as={NavLink}
                      to={url}
                      target="_blank"
                      variant="subtitle1"
                      className="heading"
                    >
                      {title}
                    </Typography>
                  </div>
                  <Typography variant="body2" color="#FFFFFF" sx={{ pt: 2 }}>
                    {content_text.substr(0, 50)}...
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Box
        mt={2}
        sx={{ display: "flex", justifyContent: "center" }}
        className="pagination-nav sx-pagination"
      >
        <Pagination
          className="paginations"
          count={Math.ceil(items.length / itemsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default Highlights;
