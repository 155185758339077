import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Typography from "@mui/material/Typography";
import "../style.css";

const mockData = [
  {
    image: "images/sliderImages/1.jpg",
    title: "2024 Montana Elections: A Spotlight on Key Races",
    content_text:
      "Explore the 2024 Montana elections, focusing on key candidates running for U.S. Senate, Governor, and Montana's 2nd Congressional District, representing diverse political perspectives.",
  },
  {
    image: "images/sliderImages/2.jpg",
    title: "Montana's 2024 U.S. House Election: A Look at the Leading Candidates",
    content_text:
      "Explore the 2024 U.S. House race in Montana's 2nd Congressional District. Learn about the key candidates from both parties and the issues shaping this pivotal election.",
  },
  {
    image: "images/sliderImages/3.jpg",
    title: "Montana First: Jon Tester’s Fight for Veterans, Agriculture, and Healthcare",
    content_text:
      "Learn about Jon Tester’s commitment to Montana's working class, veterans, and rural communities. Discover his bipartisan approach and dedication to solving issues that matter most to Montanans.",
  },
  {
    image: "images/sliderImages/4.jpg",
    title: "Bridging the Divide: Joel Krautter's Commitment to Bipartisan Leadership",
    content_text:
      "Discover Joel Krautter's journey as a former Montana lawmaker and attorney focused on bipartisan leadership, advocating for pragmatic solutions to bridge the political divide in his bid for Congress",
  },
];
const Slider = () => {
  return (
    <div className="header-top-wrapper">
      {
        <Carousel
          showArrows={true}
          autoPlay
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          stopOnHover={true}
          dots={false}
          className="carousel-wrapper"
        >
          {mockData.map((sData) => {
            const { title, image, content_text } = sData;
            return (
              <div className="imageWrapper">
                <img src={image} alt={title} />
                <div className="bannerContent">
                  <Typography variant="h1" component="h1">
                    {title}
                  </Typography>
                  <Typography variant="p" component="p">
                    {content_text}
                  </Typography>
                </div>
              </div>
            );
          })}
        </Carousel>

      }
    </div>
  );
};

export default Slider;
