const eventTypes = {
    click: "click",
}

export const gaConstants = {
    olympicButton: {
        category: "Button",
        action: eventTypes.click,
        label: "Highlights Olympics Button",
    },
    suggestionBoxArrows: {
        category: "Button",
        action: eventTypes.click,
        label: "Suggestion Box Arrow",
    },
    newsPagination : {
        category: "Button",
        action: eventTypes.click,
        label: "News Pagination",
    },
    search : {
        category: "Button",
        action: eventTypes.click,
        label: "Search Icon",
    },
    highlights: {
        category: "Button",
        action: eventTypes.click,
        label: "Highlights",
    },
    footerPagination: {
        category: "Button",
        action: eventTypes.click,
        label: "Footer Pagination",
    },
    tabChange: {
        category: "Tabs",
        action: eventTypes.click,
        label: "Mashable Feeds & Podcasts Tab",
    },
    menuLink1: {
        category: "IconButton",
        action: eventTypes.click,
        label: "ZEN NEST Menu Link",
    },
    menuLink2: {
        category: "IconButton",
        action: eventTypes.click,
        label: "PARIS 2024 Menu Link",
    },
    menuLink3: {
        category: "IconButton",
        action: eventTypes.click,
        label: "ABOUT - LES Menu Link",
    },
    socialLink1: {
        category: "IconButton",
        action: eventTypes.click,
        label: "XIcon",
    },
    socialLink2: {
        category: "IconButton",
        action: eventTypes.click,
        label: "Facebook Icon",
    },
    socialLink3: {
        category: "IconButton",
        action: eventTypes.click,
        label: "Linkedin Icon",
    },
    socialLink4: {
        category: "IconButton",
        action: eventTypes.click,
        label: "Instagram Icon",
    },
    readMore: {
        category: "Link",
        action: eventTypes.click,
        label: "READ MORE",
    },
    default: {
        category: "IconButton",
        action: eventTypes.click,
        label: "default",
    },
}