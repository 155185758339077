import { useEffect, useState } from "react";
import { configuration } from "../config";

const { baseUrl } = configuration;

export const useGetData = (url) => {
  const [state, setState] = useState({ loading: true, data: [], error: null });
  const [isRefetch, setRefetch] = useState(false);
  const refetch = () => {
    setRefetch(!isRefetch);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((s) => ({
          data: [],
          error: null,
          loading: true,
        }));
        const response = await fetch(`${baseUrl}${url}`, {
          method: "GET",
        });
        if (response.status === 200) {
          const data = await response.json();
          setState(() => ({
            error: null,
            data,
            loading: false,
          }));
        }
      } catch (error) {
        setState(() => ({
          data: [],
          loading: false,
          error: JSON.stringify(error),
        }));
        console.error("Error fetching files", error);
      }
    };
    fetchData();
  }, [url, isRefetch]);
  return {
    ...state,
    refetch,
  };
};
