import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <Typography variant="body2" pt={"30px"} color="white" align="center">
        {"Copyright © "} {new Date().getFullYear()}
        {" "}
        <Link style={{ color: "white" }} to="https://lee.net/" target="__blank">Lee Enterprises</Link>
        {"."}
      </Typography>
      <Typography
        pt={"8px"}
        pb={"50px"}
        color="white"
        variant="p"
        component="p"
      >
        All rights reserved
      </Typography>
    </div>
  );
};

export default Footer;
